// DNS Prefetch
(function(document) {
  var protocol = location.protocol;

  // httpsだったら処理を抜ける
  if(protocol == 'https:'){
    return this;
  } else {
    var servers = [
      /* CDN */
      "code.jquery.com",
      "ajax.googleapis.com",
      "maxcdn.bootstrapcdn.com",

      /* google analytics, google +1 */
      "oauth.googleusercontent.com",
      "ssl.gstatic.com",
      "accounts.google.com",
      "apis.google.com",
      "www.google-analytics.com",
      "www.googletagmanager.com",

      /* facebook */
      "www.facebook.com",
      "s-static.ak.facebook.com",
      "static.ak.fbcdn.net",
      "static.ak.facebook.com",
      "connect.facebook.net",

      /* twitter */
      "twitter.com",
      "cdn.api.twitter.com",
      "p.twitter.com",
      "platform.twitter.com",

      /* instagram */
      "instagram.com"

    ], i, e, link = document.createDocumentFragment();

    for (i = servers.length-1; i >= 0; i--) {
      e = document.createElement('link');
      e.setAttribute('rel', 'dns-prefetch');
      e.setAttribute('href', '//' + servers[i]);
      link.appendChild(e);
    }

    document.getElementsByTagName('head')[0].appendChild(link);
  }
}(document));

